/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define the marquee animation */
@keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(80%); /* Adjust to match half the total width */
    }
    0%{
      transform: translateX(-100%);
  
    }
  }
  .rtl {
    direction: rtl;
  }
  @media (max-width: 1190px) and (max-height: 782px) {
    .promo-section {
      flex-direction: column;
    }
  }
  @media (max-width: 1190px) {
    .custom-promo-section {
      flex-direction: column !important; /* Force column layout */
    }
  }
  
  
  /* Marquee track for smooth scrolling */
  .marquee-track {
    display: flex;
    gap: 1rem;
    animation: marquee 15s linear infinite;
    /* width: calc(150%); Double the width to accommodate two sets of images */
  }
  
  